import { EnumType } from 'json-to-graphql-query';
import { shuffle } from 'lodash';
import { GetStaticPropsResult } from 'next';
import React, { useContext, useEffect } from 'react';
import { ContentfulClassCategories } from '../adapters/backendDTOs';
import LocalStorageHelper from '../adapters/helpers/localStorageHelper';
import { sessionService } from '../application/session';
import teacherService from '../application/teachers';
import { userService } from '../application/user';
import { CarouselBML } from '../components/carousel/carousel';
import CarouselGroup from '../components/carousel/carouselGroup';
import NoResultsNotice from '../components/carousel/noResultsNotice';
import Filters from '../components/filters/filters';
import { HeroCarousel } from '../components/heroCarousel/heroCarousel';
import Layout from '../components/layout/layout';
import { AppContext, Types } from '../context';
import { getCarouselGroups } from '../domain/carousels';
import { areFiltersActive, filterKeys } from '../domain/filters';
import generateClassCollectionQuery from '../graphql/queries/generateClassCollectionQuery';
import useUserWithRedirects from '../hooks/useUser';
import {
  ClassFragment,
  TeacherFragment,
} from '../typescript/generated/codegen';

interface HomeProps {
  teachers: TeacherFragment[];
  heroCarouselClasses: ClassFragment[];
  availableFilters: ContentfulClassCategories[];
}

const Home: React.FC<HomeProps> = ({
  teachers,
  heroCarouselClasses,
  availableFilters,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const user = useUserWithRedirects();

  // Put teachers into global state, would be nice to make local and not slow up page load
  useEffect(() => {
    dispatch({
      type: Types.SET_TEACHERS,
      payload: teachers,
    });
  }, [dispatch, teachers]);

  // Put available filters and classification categories into state
  useEffect(() => {
    dispatch({ type: Types.SET_AVAILABLE_FILTERS, payload: availableFilters });
    dispatch({
      type: Types.SET_CLASSIFICATION_CATEGORIES,
      payload: availableFilters,
    });
  }, [dispatch, availableFilters]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      const userServiceA = userService();

      const recommendations = await userServiceA.fetchUserRecommendations();
      dispatch({ type: Types.SET_RECOMMENDATIONS, payload: recommendations });
    };

    if (user.isLoggedIn) fetchRecommendations();
  }, [dispatch, user.isLoggedIn]);

  // initialise filters via query params or localStorage if set
  useEffect(() => {
    const storageHelper = LocalStorageHelper.getInstance();
    const localStorageFilters = storageHelper.getFilterData();
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Decode any query params based on our filter keys and split them out into arrays
    const params = filterKeys.reduce((acc, item) => {
      const param = urlSearchParams.get(item);

      if (!param) {
        return acc;
      }
      // Decode values and split on comma - we also check if the current filter item is duration and parse the string back to an integer
      const values = decodeURIComponent(param)
        .split(',')
        .map(v => (item === 'duration' ? parseInt(v) : v));

      return {
        ...acc,
        [item]: values,
      };
    }, {});

    // Check if we have any query params that match our filters, if so the user might have
    // arrived at the app via a marketing url so set their filters based on the query params
    // Or fall back to localStorage values if they exist
    // eslint-disable-next-line no-prototype-builtins
    if (params && filterKeys.some(key => params.hasOwnProperty(key))) {
      dispatch({
        type: Types.SET_FILTERS,
        payload: params,
      });
    } else if (localStorageFilters) {
      dispatch({
        type: Types.SET_FILTERS,
        payload: localStorageFilters,
      });
    }
  }, [dispatch]);

  const carouselGroups = getCarouselGroups(
    state.filters,
    state.settings.classificationCategories
  );

  // check that all filterable carousels have been fetched and if they have and
  // none of the carousels contain data then show 'no results found' notice
  const noResultsFound =
    Object.keys(state.carousels).length > 0 &&
    Object.entries(state.carousels).every(
      ([, { isFetching, data }]) =>
        isFetching === false && data && data.length === 0
    );

  const favouritedClassIds = state.settings.user?.settings?.favourited?.map(
    fav => fav.beid
  );

  const recomendations = state.recommendations.recommendations?.map(
    rec => rec.id
  );

  return (
    <Layout title="Home">
      <HeroCarousel sessions={heroCarouselClasses} />

      <div>
        {/* For SEO */}
        <h1 className="hidden">Home</h1>
        <Filters />

        <div className="pt-12 pb-24 space-y-4">
          <>
            {!areFiltersActive(state.filters) && (
              <>
                <CarouselBML
                  id="recently-added"
                  title="Recently Added"
                  query={generateClassCollectionQuery({
                    order: new EnumType('publishedAt_DESC'),
                  })}
                  size="big"
                />
                {user.isLoggedIn && (
                  <>
                    {favouritedClassIds && (
                      <CarouselBML
                        id="favourites-row"
                        title="Your Favourites"
                        query={generateClassCollectionQuery({
                          where: {
                            BEID_in: favouritedClassIds,
                          },
                          limit: 20,
                        })}
                        size="big"
                      />
                    )}
                    {recomendations && (
                      <CarouselBML
                        id="for-you"
                        title="For you"
                        query={generateClassCollectionQuery({
                          where: { BEID_in: recomendations },
                        })}
                        size="big"
                      />
                    )}
                  </>
                )}
              </>
            )}
            {noResultsFound && <NoResultsNotice />}
            {carouselGroups &&
              carouselGroups.map(group => (
                <CarouselGroup key={group.id} {...group} />
              ))}
          </>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const getServerSideProps = async (): Promise<
  GetStaticPropsResult<HomeProps>
> => {
  const applicationTeachers = teacherService();
  const applicationSessions = sessionService();

  const [teachers, featureClasses, filters] = await Promise.all([
    applicationTeachers.getAllTeachers(),
    applicationSessions.getFeatureClasses({
      limit: 99,
    }),
    applicationSessions.getClassClassifications(),
  ]);

  const shuffledSessions = shuffle(featureClasses).filter((_item, i) => i < 6);

  return {
    props: {
      teachers,
      heroCarouselClasses: shuffledSessions,
      availableFilters: filters,
    },
  };
};
