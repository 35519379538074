import clsx from 'clsx';
import React, { useContext } from 'react';
import { AppContext, Types } from '../../context';

interface FiltersClassificationsProps {}

const FiltersClassifications: React.FC<FiltersClassificationsProps> = () => {
  const { state, dispatch } = useContext(AppContext);

  const handleClassificationToggle = (classification: string) => {
    const classificationFilter = state?.filters?.classification || [];

    const isSelected =
      state.filters &&
      state.filters.classification &&
      state.filters.classification.includes(classification);

    const updatedClassificationFilter = isSelected
      ? classificationFilter.filter(c => c !== classification)
      : [...classificationFilter, classification];

    dispatch({
      type: Types.SET_FILTERS,
      payload: {
        ...state.filters,
        classification: updatedClassificationFilter,
      },
    });
  };

  // pull classifications out
  const classifications = state.settings.classificationCategories.map(i => {
    return i.classification;
  });

  return (
    <div className="grid items-center grid-cols-2 gap-2 md:grid-cols-5">
      {classifications.map(classification => {
        // if no classifications are set in filters then display as active
        // otherwise check if classification is set in filters
        const isActive = !state?.filters?.classification
          ? false
          : state.filters &&
            state.filters.classification &&
            state.filters.classification.includes(classification);
        const dataSelector = `homepage-filter-${classification}`;

        // Some of the names of the filters start to get a little long for the bubbles that they are shown in
        // Use the classification alias to change the name that is displayed in the bubble while keeping the value the same
        let classificationText;
        switch (classification) {
          case 'Mindfulness':
            classificationText = 'Mind';
            break;
          default:
            classificationText = classification;
            break;
        }

        return (
          <button
            data-cy={dataSelector}
            key={`homepage-filter-${classification}`}
            className={clsx(
              'font-family-semibold text-black text-center border border-accent-light rounded-full cursor-pointer overflow-hidden overflow-ellipsis',
              'h-48px w-full px-12 lg:px-6 md:px-3',
              isActive && 'bg-accent-light',
              !isActive && 'hover:border-accent',
              'shadow-lg',

              'focus:outline-none'
            )}
            onClick={() => handleClassificationToggle(classification)}
          >
            {classificationText}
          </button>
        );
      })}
    </div>
  );
};

export default FiltersClassifications;
