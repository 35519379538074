import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { BiHeart } from 'react-icons/bi';
import { AppContext, Types } from '../../context';
import { State } from '../../context/filters';
import { routes } from '../../domain/websiteDomain';
import useUserWithRedirects from '../../hooks/useUser';
import FiltersClassifications from './filtersClassifications';
import FiltersIcon from './filtersSideModal/filtersIcon';
import SearchBox from './homepageSearchBox';

interface FiltersProps {}

const Filters: React.FC<FiltersProps> = () => {
  const { dispatch, state } = useContext(AppContext);
  const router = useRouter();
  const user = useUserWithRedirects();

  const generateQueryString = (values: any) => {
    if (!values) {
      router.push(router.pathname);
      return;
    }

    // For each of the values, create a query param mapping and join the values
    // We can decode this on the home page when a user visits from a link with query params set
    Object.keys(values).forEach(key => {
      if (Array.isArray(values[key]) && values[key].length) {
        router.query[encodeURIComponent(key)] = encodeURIComponent(
          values[key].join(',')
        );
      }
    });

    // Push our query into the address bar
    router.push(router.pathname, { query: router.query });
  };

  const handleSubmitSearchBox = (searchTerm: string) => {
    const filters = state.filters;

    if (filters?.searchTerm && !searchTerm) {
      delete filters.searchTerm;

      generateQueryString(filters);

      dispatch({
        type: Types.SET_FILTERS,
        payload: filters,
      });

      return;
    }

    let newFilterValues;
    if (searchTerm) {
      newFilterValues = {
        ...filters,
        ['searchTerm']: searchTerm ? [searchTerm] : [],
      };
    }

    dispatch({
      type: Types.SET_FILTERS,
      payload: newFilterValues as State,
    });
  };

  const openFiltersSideModal = () =>
    dispatch({ type: Types.SET_IS_FILTERS_OPEN, payload: true });

  const getSearchTerm = (state: any) => {
    if (
      state &&
      !isEmpty(state.filters) &&
      !isEmpty(state.filters.searchTerm)
    ) {
      return state?.filters?.searchTerm[0];
    }

    return '';
  };

  return (
    <div className="sticky z-20 flex justify-center w-full py-4 bg-white shadow lg:justify-between sm:py-6 content top-56px">
      <div className="pr-2 text-center sm:pr-10">
        <FiltersClassifications />

        <div className="block pt-6 text-sm md:hidden text-accent">
          <button onClick={openFiltersSideModal}>More filters</button>
        </div>
      </div>

      <div className="flex gap-2 md:inline-flex">
        <SearchBox
          values={{ searchTerm: getSearchTerm(state) }}
          submit={handleSubmitSearchBox}
        />
        {user.isLoggedIn && (
          <div
            data-cy="user-fav-button"
            className="bg-white border rounded-full shadow-xl border-accent-light"
          >
            <Link href={routes.USER_FAVOURITES}>
              <a
                className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none"
                aria-label="Favourites"
              >
                <BiHeart color="black" />
              </a>
            </Link>
          </div>
        )}

        <div className="hidden sm:block">
          <FiltersIcon />
        </div>
      </div>
    </div>
  );
};

export default Filters;
