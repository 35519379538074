import clsx from 'clsx';
import React, { useContext } from 'react';
import { AppContext } from '../../context';
import { CarouselBML, CarouselProps } from './carousel';

export interface CarouselGroupProps {
  id: string;
  title: string;
  classification?: string;
  rows: CarouselProps[];
}

const CarouselGroup: React.FC<CarouselGroupProps> = ({ title, rows, id }) => {
  const { state } = useContext(AppContext);

  const noResultsForGroup = rows
    .map(row => row.id)
    .every(id => {
      const carousel = state.carousels[id];

      return (
        carousel !== undefined &&
        !carousel.isFetching &&
        carousel.data &&
        carousel.data.length === 0
      );
    });

  if (noResultsForGroup) return null;

  return (
    <div data-cy={id}>
      <div
        className={clsx(
          'content flex items-center py-4',
          !rows[0].title && 'mb-3'
        )}
      >
        <span className={clsx('text-5xl font-family-bold')}>{title}</span>
      </div>

      <div className="space-y-8">
        {rows.map(carousel => (
          <CarouselBML key={carousel.id} {...carousel} />
        ))}
      </div>
    </div>
  );
};

export default CarouselGroup;
