import React from 'react';
import { useForm } from 'react-hook-form';
import { BiSearchAlt2 } from 'react-icons/bi';

interface SearchBoxProps {
  values: FormData;
  submit: (searchTerm: string) => void;
}

export interface FormData {
  searchTerm: string;
}

const SearchBox: React.FC<SearchBoxProps> = ({ submit, values }) => {
  const { register, handleSubmit, setValue } = useForm<FormData>();

  setValue('searchTerm', values.searchTerm);

  const onSubmit = (data: FormData) => {
    submit(data.searchTerm);
  };

  return (
    <div data-cy="search-term-input">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center bg-white border rounded-full shadow-xl border-accent-light">
          <div>
            <button
              className="flex items-center justify-center w-12 h-12 text-white rounded-full cursor-default focus:outline-none"
              aria-label="Search Class"
            >
              <BiSearchAlt2 color="black" />
            </button>
          </div>

          <input
            name="searchTerm"
            ref={register}
            className="w-full border-transparent rounded-r-full"
            type="text"
            placeholder="Search"
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
