import React, { useContext } from 'react';
import { BiSlider } from 'react-icons/bi';
import { AppContext, Types } from '../../../context';

interface FiltersIconProps {}

const FiltersIcon: React.FC<FiltersIconProps> = () => {
  const { state, dispatch } = useContext(AppContext);

  const setFilterCount =
    typeof state.filters === 'object' &&
    Object.values(state.filters).reduce((acc, cur) => {
      if (Array.isArray(cur)) {
        // Don't add the searchTerm filter to the count unless there is a
        // value other than an empty string
        if (cur[0] === '') return acc;

        return cur.length + acc;
      }
      if (cur !== undefined && cur !== null) return acc + 1;

      return acc;
    }, 0);

  const handleClick = () =>
    dispatch({
      type: Types.SET_IS_FILTERS_OPEN,
      payload: !state.modals.isFiltersOpen,
    });

  return (
    <div
      data-cy="filter-icon"
      className={`group relative hover:text-accent-hover cursor-pointer transition-all ${
        state.modals.isMenuOpen ? 'opacity-0' : 'opacity-100'
      }`}
      onClick={handleClick}
    >
      <div className="flex items-center bg-white border rounded-full shadow-xl border-accent-light">
        <button
          className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none"
          aria-label="Filter"
        >
          <BiSlider color="black" />
        </button>
      </div>

      {setFilterCount > 0 && (
        <div
          className="absolute flex items-center justify-center text-xs font-semibold text-center transition-colors rounded-full h-7 w-7 -bottom-1 -right-2 bg-accent text-body animate-ping-2"
          style={{ borderWidth: 3, borderColor: '#fff' }}
        >
          <div>{setFilterCount}</div>
        </div>
      )}
    </div>
  );
};

export default FiltersIcon;
