import clsx from 'clsx';
import Image from 'next/image';
import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { getSessionRoute, getSessionTitle } from '../../domain/class';
import { ClassFragment } from '../../typescript/generated/codegen';
import Button from '../common/button';
import SessionDetails from '../hero/sessionDetails';

interface HeroCarouselProps {
  sessions: ClassFragment[];
}

export const HeroCarousel: React.FC<HeroCarouselProps> = ({ sessions }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = sessions
    .filter(Boolean)
    .map((session, i) => (
      <HeroImage key={session.beid} classBML={session} isPriority={i === 0} />
    ));

  const beforeChange = (_oldIndex: number, newIndex: number) =>
    setActiveIndex(newIndex);

  const customPaging = (i: number) => {
    const isActive = i === activeIndex;

    return (
      <div
        className={clsx(
          'relative h-2 mx-2 md:mx-4 rounded-full cursor-pointer',
          isActive ? 'bg-accent' : 'bg-gray-500'
        )}
        style={{ width: `${(1 / items.length) * 100 - 5}vw` }}
      />
    );
  };

  const settings: Settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    dots: true,
    customPaging,
    beforeChange,
    dotsClass: 'hero-carousel-dots',
    arrows: false,
  };

  return (
    <div className="relative text-white bg-black h-hero-full xl:h-lg-hero-tight">
      {items && items.length > 0 && <Slider {...settings}>{items}</Slider>}
    </div>
  );
};

interface HeroImageProps {
  classBML: ClassFragment;
  isPriority?: boolean;
}

export const HeroImage = ({ classBML, isPriority = false }: HeroImageProps) => {
  return (
    <div className="relative flex items-center text-white h-hero-full xl:h-lg-hero-tight">
      {classBML?.image?.url && (
        <Image
          className={`h-full w-full object-cover`}
          layout="fill"
          src={`${classBML.image.url}?w=1600&q=80`}
          alt={getSessionTitle(classBML)}
          priority={isPriority}
        />
      )}
      <div className="absolute inset-0 transition-opacity duration-500 bg-black pointer-events-none opacity-40" />
      <div className="z-20 flex flex-col gap-8 content">
        <SessionDetails session={classBML} />
        <Button buttonStyle="primary" href={getSessionRoute(classBML)}>
          More Info
        </Button>
      </div>
    </div>
  );
};
