import { EnumType } from 'json-to-graphql-query';
import { ContentfulClassCategories } from '../adapters/backendDTOs';
import { CarouselGroupProps } from '../components/carousel/carouselGroup';
import { State as Filters } from '../context/filters';
import generateClassCollectionQuery from '../graphql/queries/generateClassCollectionQuery';
import { ClassFragment } from '../typescript/generated/codegen';
import { areFiltersActive } from './filters';
import { User } from './user';
import { DataStoreKeys } from './websiteDomain';

export const getCarouselGroups = (
  filters: any,
  classificationCategories: ContentfulClassCategories[]
): CarouselGroupProps[] => {
  const filtersActive = areFiltersActive(filters);

  const classificationGroups = classificationCategories
    .map(cc => {
      const classification = cc.classification;
      const categories = cc.categories;

      return {
        id: `${classification.toLowerCase()}-group`,
        title: classification,
        classification,
        rows: [
          // Only show recently added if no filters are active
          ...(filtersActive
            ? []
            : [
                {
                  id: `recent-${classification}`,
                  title: 'Recently Added',
                  query: generateClassCollectionQuery({
                    filters,
                    where: { classification },
                    order: new EnumType('publishedAt_DESC'),
                  }),
                },
              ]),
          ...categories.map(category => ({
            id: `${classification}-${category}`,
            title: category,
            query: generateClassCollectionQuery({
              filters: filters,
              where: { classification, type: category },
              order: new EnumType('publishedAt_DESC'),
            }),
          })),
        ],
      };
    })
    .filter(Boolean)
    .filter(group => {
      if (
        group?.classification &&
        filters?.classification &&
        filters?.classification.length
      ) {
        return filters.classification.includes(group?.classification);
      }
      return true;
    });

  return classificationGroups;
};

export const getRecentlyViewedCarouselGroupProps = (
  filters?: Filters,
  user?: User
) => {
  if (
    !user?.settings ||
    !user.settings[DataStoreKeys.VIEWED] ||
    user.settings[DataStoreKeys.VIEWED].length === 0
  )
    return null;

  const recentlyViewed = user.settings[DataStoreKeys.VIEWED]
    .filter((_item, i: number) => i < 10)
    .map(item => item.id);

  const parseQueryResponse = (sessions: ClassFragment[]) => {
    return sessions.sort((a, b) => {
      const A = recentlyViewed.indexOf(a.id!);
      const B = recentlyViewed.indexOf(b.id!);
      if (A < B) return -1;
      if (A > B) return 1;
      return 0;
    });
  };

  return {
    id: 'recentlyViewed',
    title: 'Recently Viewed',
    rows: [
      {
        id: 'recently-viewed',
        title: '',
        query: generateClassCollectionQuery({
          filters,
          where: { id_in: recentlyViewed },
        }),
        parseQueryResponse,
      },
    ],
  };
};
