import React, { useContext } from 'react';
import { AppContext, Types } from '../../context';

interface NoResultsNoticeProps {}

const NoResultsNotice: React.FC<NoResultsNoticeProps> = () => {
  const { dispatch } = useContext(AppContext);

  const handleOpenFilters = () =>
    dispatch({
      type: Types.SET_IS_FILTERS_OPEN,
      payload: true,
    });

  return (
    <div className="text-2xl text-center content">
      <div>
        Unfortunately no results matched your search criteria. Try changing{' '}
        <span
          className="cursor-pointer text-accent"
          onClick={handleOpenFilters}
        >
          the filters
        </span>
      </div>
    </div>
  );
};

export default NoResultsNotice;
